import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import axios from 'axios';
import { create_merchand_account_Url, leadApiUrl, paragonUrl } from '../apiSheet';
import { getAllCity, getAllState } from '../service/action/userAction';
import { createMerchandAccount } from '../service/action/Club/clubAction';
import moment from "moment";
import InputMask from "react-input-mask";
import materialTheme from '../materialUi';
import { ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const SignupMerchant = (props) => {
    const [startDate, setStartDate] = useState('')
    const history = useHistory()
    const signUp = useSelector(state => state.signupReducer.response.data)
    const [type, setType] = useState()
    const [userId, setUserId] = useState()
    useEffect(() => {
        if (props.signup?.success) {
            if (signUp.user.is_organizer == 1) {
                setType(1)
                setUserId(signUp.user.organizer[0])
            } else if (signUp.user.is_club == 1) {
                setType(4)
                setUserId(signUp.user.clubs[0])
            }
        }
    }, [props.signup])

    const getAllBank = props.getBanksHandler

    useEffect(() => {     
        // getAllBank()
        getAllState()
    }, [])

    const allBank = useSelector(state => state.getBanksReducer.user)
    const deleteMessage = useSelector(state => state.deleteBankReducer.message)
    const Msg = useSelector(state => state.createMerchandAccountReducer.message)
    const networkErrorMsg = useSelector(state => state.createMerchandAccountReducer.networkErr)
    const allstate = useSelector(state => state.getStateReducer.states)
    const allcity = useSelector(state => state.getCityReducer.citys)
    const preferred = allBank.find(e => e.default == 1)
    const alternet = allBank.filter((e) => e.default !== 1)

    const initialState = {
        bank_name: "",
        reseller_password: "",
        application_template_id: "",
        terminal_template_ids: "",
        company_legal_name: "",
        company_dba_name: "",
        company_ownership_type: "",
        company_federal_tax_id: "",
        address: "",
        postal_code: "",
        state: "",
        city: "",
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
        date_of_birth: "",
        social_security_number: "",
        drivers_license_number: "",
        bank_routing_number: "",
        bank_account_number: ""

    };

    const initialError = {
        reseller_username: false,
        reseller_password: false,
        application_template_id: false,
        terminal_template_ids: false,
        company_legal_name: false,
        company_dba_name: false,
        company_ownership_type: false,
        company_federal_tax_id: false,
        address: false,
        postal_code: false,
        state: false,
        city: false,
        first_name: false,
        last_name: false,
        email_address: false,
        phone: false,
        date_of_birth: false,
        social_security_number: false,
        drivers_license_number: false,
        bank_routing_number: false,
        bank_account_number: false,
        dueDate: false
    }





    const [bank, setBank] = useState({
        reseller_username: "",
        reseller_password: "",
        application_template_id: "",
        terminal_template_ids: "",
        company_legal_name: "",
        company_dba_name: "",
        company_ownership_type: "",
        company_federal_tax_id: "",
        address: "",
        postal_code: "",
        state: "",
        city: "",
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
        date_of_birth: "",
        social_security_number: "",
        drivers_license_number: "",
        bank_routing_number: "",
        bank_account_number: ""
    })

    const [errors, setErrors] = useState({
        reseller_username: false,
        reseller_password: false,
        application_template_id: false,
        terminal_template_ids: false,
        company_legal_name: false,
        company_dba_name: false,
        company_ownership_type: false,
        company_federal_tax_id: false,
        address: false,
        postal_code: false,
        state: false,
        city: false,
        first_name: false,
        last_name: false,
        email_address: false,
        phone: false,
        date_of_birth: false,
        social_security_number: false,
        drivers_license_number: false,
        bank_routing_number: false,
        bank_account_number: false,
        dueDate: false
    })





    // const paycheck = () => {
    //     if (bank.payrequired == 0 && bank.paymentchecked == false) {
    //         setBank(prevState => ({
    //             ...prevState,
    //             payrequired: 1,
    //             paymentchecked: true
    //         }))
    //     } else if (bank.payrequired == 1 && bank.paymentchecked == true) {
    //         setBank(prevState => ({
    //             ...prevState,
    //             payrequire: 0,
    //             paymentchecked: false
    //         }))
    //     }
    // }

    const bankMsg = useSelector(state => state.createBankDetailsReducer.message)
    const editbankMsg = useSelector(state => state.editBankReducer.message)

    const eventHandler = (e) => {
        const { id, value, name } = e.target
        setBank(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "reseller_username":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        reseller_username: "Reseller user name can not be empty"
                    }))

                    document.getElementById("reseller_username").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        reseller_username: false
                    }))

                    document.getElementById("reseller_username").classList.remove("error")
                }

                break;

            case "reseller_password":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        reseller_password: "Reseller Password can not be empty"
                    }))

                    document.getElementById("reseller_password").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        reseller_password: false
                    }))

                    document.getElementById("reseller_password").classList.remove("error")
                }

                break;

            case "application_template_id":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        application_template_id: "Apllication template id can not be empty"
                    }))

                    document.getElementById("application_template_id").classList.add("error")
                }
                // else
                //     if (!/^[0-9]+$/.test(value)) {
                //         setErrors(prevState => ({
                //             ...prevState,
                //             application_template_id: "Only numeric value is accept"
                //         }))

                //         document.getElementById("application_template_id").classList.add("error")
                //     }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        application_template_id: false
                    }))

                    document.getElementById("application_template_id").classList.remove("error")
                }

                break;

            case "terminal_template_ids":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        terminal_template_ids: "Terminal template ids can not be empty"
                    }))

                    document.getElementById("terminal_template_ids").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        terminal_template_ids: false
                    }))

                    document.getElementById("terminal_template_ids").classList.remove("error")
                }

                break;
            case "company_legal_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        company_legal_name: "Comapany legal can not be empty"
                    }))

                    document.getElementById("company_legal_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        company_legal_name: false
                    }))

                    document.getElementById("company_legal_name").classList.remove("error")
                }

                break;

            case "company_dba_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        company_dba_name: "Company dba name can not be empty"
                    }))

                    document.getElementById("company_dba_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        company_dba_name: false
                    }))

                    document.getElementById("company_dba_name").classList.remove("error")
                }

                break;

            case "company_ownership_type":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        company_ownership_type: "Company ownership type can not be empty"
                    }))

                    document.getElementById("company_ownership_type").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        company_ownership_type: false
                    }))

                    document.getElementById("company_ownership_type").classList.remove("error")
                }

                break;
            case "company_federal_tax_id":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        company_federal_tax_id: "Company federal tax id can not be empty"
                    }))

                    document.getElementById("company_federal_tax_id").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        company_federal_tax_id: false
                    }))

                    document.getElementById("company_federal_tax_id").classList.remove("error")
                }

                break;
            case "address":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        address: "Address can not be empty"
                    }))

                    document.getElementById("address").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        address: false
                    }))

                    document.getElementById("address").classList.remove("error")
                }

                break;
            case "postal_code":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        postal_code: "Postal code can not be empty"
                    }))

                    document.getElementById("postal_code").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        postal_code: false
                    }))

                    document.getElementById("postal_code").classList.remove("error")
                }

                break;

            case "state":

                // if (value !== "") {
                //     props.getAllCityHandler({ id: value })
                // }
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        state: "State can not be empty."
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        state: false
                    }))
                }
                break;



            case "city":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        city: "City can not be empty."
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        city: false
                    }))

                }

                break;

            case "first_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        first_name: "First name can not be empty"
                    }))

                    document.getElementById("first_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        first_name: false
                    }))

                    document.getElementById("first_name").classList.remove("error")
                }

                break;

            case "last_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        last_name: "Last name can not be empty"
                    }))

                    document.getElementById("last_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        last_name: false
                    }))

                    document.getElementById("last_name").classList.remove("error")
                }

                break;

            case "email_address":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        email_address: "Email address can not be empty"
                    }))

                    document.getElementById("email_address").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        email_address: false
                    }))

                    document.getElementById("email_address").classList.remove("error")
                }

                break;

            case "phone":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        phone: "Phone number can not be empty"
                    }))

                    document.getElementById("phone").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        phone: false
                    }))

                    document.getElementById("phone").classList.remove("error")
                }

                break;

            case "startDate":
                setStartDate(value)
                if (value) {
                    const y = new Date(value)
                    const c = new Date()
                    const selectYear = y.getFullYear()
                    const currentYear = c.getFullYear()
                    const diff = currentYear - selectYear

                    if (diff < 18) {
                        setErrors(prevState => ({
                            ...prevState,
                            dueDate: "Age must be 18 or above"
                        }))
                        document.getElementById("due_date").classList.add("error")
                    } else {
                        setErrors(prevState => ({
                            ...prevState,
                            dueDate: false,
                        }))
                        document.getElementById("due_date").classList.remove("error")
                    }
                }

                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        dueDate: "Please select date of birth"
                    }))
                    document.getElementById("due_date").classList.add("error")
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        dueDate: false,
                    }))
                    document.getElementById("due_date").classList.remove("error")
                }
            // case "date_of_birth":
            //     // setStartDate(value)

            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             date_of_birth: "Please select date of birth"
            //         }))
            //         document.getElementById("date_of_birth").classList.add("error")
            //     }
            //     else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             date_of_birth: false,
            //         }))
            //         document.getElementById("date_of_birth").classList.remove("error")
            //     }


            case "social_security_number":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        social_security_number: "Social security number can not be empty"
                    }))

                    document.getElementById("social_security_number").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        social_security_number: false
                    }))

                    document.getElementById("social_security_number").classList.remove("error")
                }

                break;

            case "drivers_license_number":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        drivers_license_number: "Driving license number can not be empty"
                    }))

                    document.getElementById("drivers_license_number").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        drivers_license_number: false
                    }))

                    document.getElementById("drivers_license_number").classList.remove("error")
                }

                break;

            case "bank_routing_number":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        bank_routing_number: "Bank routing number can not be empty"
                    }))

                    document.getElementById("bank_routing_number").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        bank_routing_number: false
                    }))

                    document.getElementById("bank_routing_number").classList.remove("error")
                }

                break;

            case "bank_account_number":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        bank_account_number: "Bank account number can not be empty"
                    }))

                    document.getElementById("bank_account_number").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        bank_account_number: false
                    }))

                    document.getElementById("bank_account_number").classList.remove("error")
                }

                break;

            default:

        }
    }

    const [details, setDetails] = useState({})


    const submit = (e) => {

        e.preventDefault();

        if (bank.reseller_username == "") {
            setErrors(prevState => ({
                ...prevState,
                reseller_username: "Reseller name can not be empty"
            }))
            document.getElementById("reseller_username").classList.add("error")
        }

        if (bank.reseller_password == "") {
            setErrors(prevState => ({
                ...prevState,
                reseller_password: "Reseller password can not be empty"
            }))
            document.getElementById("reseller_password").classList.add("error")
        }

        if (bank.application_template_id == "") {
            setErrors(prevState => ({
                ...prevState,
                application_template_id: "Application template can not be empty"
            }))
            document.getElementById("application_template_id").classList.add("error")
        }

        if (bank.terminal_template_ids == "") {
            setErrors(prevState => ({
                ...prevState,
                terminal_template_ids: "Terminal template id can not be empty"
            }))
            document.getElementById("terminal_template_ids").classList.add("error")
        }

        if (bank.company_legal_name == "") {
            setErrors(prevState => ({
                ...prevState,
                company_legal_name: "Company legal name can not be empty"
            }))
            document.getElementById("company_legal_name").classList.add("error")
        }

        if (bank.company_dba_name == "") {
            setErrors(prevState => ({
                ...prevState,
                company_dba_name: "Company dba name can not be empty"
            }))
            document.getElementById("company_dba_name").classList.add("error")
        }

        if (bank.company_ownership_type == "") {
            setErrors(prevState => ({
                ...prevState,
                company_ownership_type: "Please select owenership type"
            }))
            document.getElementById("company_ownership_type").classList.add("error")
        }

        if (bank.company_federal_tax_id == "") {
            setErrors(prevState => ({
                ...prevState,
                company_federal_tax_id: "Company federal tax id can not be empty"
            }))
            document.getElementById("company_federal_tax_id").classList.add("error")
        }
        if (bank.company_federal_tax_id.length < 9) {
            setErrors(prevState => ({
                ...prevState,
                company_federal_tax_id: "Company federal tax id must be 9 characters"
            }))
            document.getElementById("company_federal_tax_id").classList.add("error")
        }

        if (bank.address == "") {
            setErrors(prevState => ({
                ...prevState,
                address: "Address can not be empty"
            }))
            document.getElementById("address").classList.add("error")
        }

        if (bank.postal_code == "") {
            setErrors(prevState => ({
                ...prevState,
                postal_code: "Postal code can not be empty"
            }))
            document.getElementById("postal_code").classList.add("error")
        }
        if (bank.postal_code < 5) {
            setErrors(prevState => ({
                ...prevState,
                postal_code: "Postal code must be 5 character long"
            }))
            document.getElementById("postal_code").classList.add("error")
        }

        if (bank.state == "") {
            setErrors(prevState => ({
                ...prevState,
                state: "State can not be empty"
            }))
            document.getElementById("state").classList.add("error")
        }

        if (bank.city == "") {
            setErrors(prevState => ({
                ...prevState,
                city: "City can not be empty"
            }))
            document.getElementById("city").classList.add("error")
        }

        if (bank.first_name == "") {
            setErrors(prevState => ({
                ...prevState,
                first_name: "First name can not be empty"
            }))
            document.getElementById("first_name").classList.add("error")
        }

        if (bank.last_name == "") {
            setErrors(prevState => ({
                ...prevState,
                last_name: "Last name can not be empty"
            }))
            document.getElementById("last_name").classList.add("error")
        }

        if (bank.email_address == "") {
            setErrors(prevState => ({
                ...prevState,
                email_address: "Email address can not be empty"
            }))
            document.getElementById("email_address").classList.add("error")
        }

        if (bank.phone == "") {
            setErrors(prevState => ({
                ...prevState,
                phone: "Phone can not be empty"
            }))
            document.getElementById("phone").classList.add("error")
        }

        if (startDate == "") {
            setErrors(prevState => ({
                ...prevState,
                dueDate: "Date of birth can not be empty"
            }))
            document.getElementById("due_date").classList.add("error")
        }
        // if (bank.date_of_birth == "") {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         date_of_birth: "Date of birth can not be empty"
        //     }))
        //     document.getElementById("date_of_birth").classList.add("error")
        // }

        if (bank.social_security_number == "") {
            setErrors(prevState => ({
                ...prevState,
                social_security_number: "Social security number can not be empty"
            }))
            document.getElementById("social_security_number").classList.add("error")
        }
        if ((bank.social_security_number.length < 9 || bank.social_security_number.length > 9) && bank.social_security_number !== "") {
            setErrors(prevState => ({
                ...prevState,
                social_security_number: "Social security number must be 9 character long"
            }))
            document.getElementById("social_security_number").classList.add("error")
        }

        if (bank.drivers_license_number == "") {
            setErrors(prevState => ({
                ...prevState,
                drivers_license_number: "Drivers license number can not be empty"
            }))
            document.getElementById("drivers_license_number").classList.add("error")
        }

        if (bank.bank_routing_number == "") {
            setErrors(prevState => ({
                ...prevState,
                bank_routing_number: "Bank routing number can not be empty"
            }))
            document.getElementById("bank_routing_number").classList.add("error")
        }

        if ((bank.bank_routing_number.length > 9 || bank.bank_routing_number.length < 9) && bank.bank_routing_number !== "") {
            setErrors(prevState => ({
                ...prevState,
                bank_routing_number: "Bank routing number must be 9 characters"
            }))
            document.getElementById("bank_routing_number").classList.add("error")
        }

        if (bank.bank_account_number == "") {
            setErrors(prevState => ({
                ...prevState,
                bank_account_number: "Bank account number can not be empty"
            }))
            document.getElementById("bank_account_number").classList.add("error")
        }

        if (bank.phone.length > 10) {

            var ret = bank.phone.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '')
            var mobileNum = ret;

        }
        else {
            var mobileNum = bank.phone
        }


        if (
            bank.reseller_username !== ""
            && bank.reseller_password !== "" && bank.application_template_id !== ""
            && bank.terminal_template_ids !== "" && bank.company_legal_name !== "" && bank.company_dba_name !== "" && bank.company_ownership_type !== "" && bank.company_federal_tax_id !== "" && bank.address !== "" && bank.postal_code !== "" && bank.state !== "" && bank.city !== "" && bank.first_name !== "" && bank.last_name !== "" && bank.email_address !== "" && bank.phone !== "" && bank.social_security_number !== "" && !(bank.social_security_number.length < 9) && bank.drivers_license_number !== "" && bank.bank_routing_number !== "" && !(bank.bank_routing_number < 9) && bank.bank_account_number !== ""
        ) {
            props.createMerchandAccountHandler({

                username: bank.reseller_username,
                password: bank.reseller_password,

            })

            setDetails(prevState => ({
                ...prevState,
                reseller_username: bank.reseller_username,
                reseller_password: bank.reseller_password,
                reseller_key: 215,
                application_template_id: "a0R0x0000029lhoEAA",
                terminal_template_ids: "a140x00000TcN00",
                company: {
                    company_legal_name: bank.company_legal_name,
                    company_dba_name: bank.company_dba_name,
                    company_ownership_type: bank.company_ownership_type,
                    company_federal_tax_id: bank.company_federal_tax_id,
                    legal_address: {
                        address: bank.address,
                        postal_code: bank.postal_code,
                        city: bank.city,
                        state: bank.state
                    }
                },
                owner_Principal: {
                    first_name: bank.first_name,
                    last_name: bank.last_name,
                    email_address: bank.email_address,
                    phone: mobileNum,
                    date_of_birth: moment(bank.due_date).format('MM/DD/YYYY'),
                    social_security_number: bank.social_security_number,
                    drivers_license_number: bank.drivers_license_number,

                },
                bank: {
                    bank_routing_number: bank.bank_routing_number,
                    bank_account_number: bank.bank_account_number
                }
            }))
        }

    }

    useEffect(() => {
        // if(success){
        //     reseller_username: "215purefacapi",
        //     reseller_password: "5LECXCyZ",
        //     reseller_key: 215,
        //     application_template_id: bank.application_template_id,
        //     correlation_id: "",
        //     terminal_template_ids: bank.terminal_template_ids,
        //     company: {
        //         company_legal_name: bank.company_legal_name,
        //         company_dba_name: bank.company_dba_name,
        //         company_ownership_type:  bank.company_ownership_type,
        //         company_federal_tax_id: bank.company_federal_tax_id,
        //         legal_address: {
        //             address: bank.address,
        //             postal_code:  bank.postal_code,
        //             city: bank.city,
        //             state:bank.state
        //         }
        //     },
        //     owner_Principal: {
        //         first_name: bank.first_name,
        //         last_name: bank.last_name,
        //         email_address: bank.email_address,
        //         phone: bank.phone,
        //         date_of_birth: startDate,
        //         social_security_number: bank.social_security_number,
        //         drivers_license_number: bank.drivers_license_number,

        //     },
        //     bank: {
        //         bank_routing_number: bank.bank_routing_number,
        //         bank_account_number: bank.bank_account_number
        //     }

        // }
    }, [])

    const notify = (msg) => toast(msg);
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setBank({ ...initialState });
        setErrors({ ...initialError })
    };


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            notify(bankMsg.message)
            resetData().then(clearState)
            props.resetCreateBankdetailsReducer()
            getAllBank()

        }
    }, [props.submit])

    useEffect(() => {
        // alert("a")
        if (props.deleteMsg?.success) {
            notify(deleteMessage.message)
            props.resetDeleteBankReducer()
            getAllBank()

        }
    }, [props.deleteMsg])

    useEffect(() => {
        // alert("a")
        if (props.merchand?.success) {

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `${Msg.access_token}`
                },
                // url: create_merchand_account_Url,
                url: `${paragonUrl}/v2/%7BversionName%7D/correlationId?versionName=pdf`,

            };

            axios(requestOptions)
                .then(resp => {
                    setDetails(prevState => ({
                        ...prevState,
                        correlation_id: resp.data.correlationId
                    }))



                    // const requestOptions = {
                    //     method: "POST",
                    //     headers: {
                    //         "Content-Type": "application/json",
                    //     },
                    //     url: `${leadApiUrl}/v2/lead`,
                    //     data: details
                    // };

                    // if (details.correlation_id !== "") {

                    //     axios(requestOptions).then(resp => {

                    //     }).catch(err => {
                    //     })
                    // }
                })
                .catch(err => {
                    // Handle Error Here
                    console.error(err);
                });
            // notify(Msg.message)
            // props.resetCreatemerchandaccountHandler()
            // resetData().then(clearState)

        } else if (props.merchand?.error) {
            if (networkErrorMsg) {
                notify(networkErrorMsg.message)
            }
        }
    }, [props.merchand])

    useEffect(() => {

        if (details.correlation_id) {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                url: `${leadApiUrl}/v2/lead`,
                data: details
            };


            axios(requestOptions).then(resp => {
                const localServerData = ({ ...resp.data, user_type: type, user_id: userId })

                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        // 'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    url: create_merchand_account_Url,
                    data: localServerData
                };
                axios(requestOptions).then(resp => {
                    notify("Merchant Account Created Successfully")

                    setTimeout(() => {
                        history.push("/login")
                        clearState()

                    }, 2000);

                }).catch(err => {
                })
            }).catch(err => {
            })
        }
    }, [details.correlation_id])

    const Today = new Date().toISOString().slice(0, 10);
    const [selectedDate, handleDateChange] = useState(null);
    return (
        <React.Fragment>
            {/* <div className="main-panel">
                <div className="content-wrapper"> */}
            <div className="text-left">
                <div className="create-team-head-event">
                    <h5 className="dash-head text-white" >Setup Merchant Account</h5>
                    {/* <div className="mt-3 mt-md-0 text-right">
                        <button className="btn-save" onClick={submit}>Save Changes</button>
                    </div> */}
                </div>
            </div>

            <div className="bank-info-tab mt-4 mt-md-0">
                {/* <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="account-tab" data-toggle="pill" href="#account" role="tab" aria-controls="pills-home" aria-selected="true">Create Merchant Account</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Set up a Merchant account to.</a>
                            </li>
                        </ul> */}
                <div className="tab-content" id="pills-tabContent">


                    <div>
                        <h5 className="merchant-title text-left mt-42">Reseller</h5>
                        <div className="row">
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Reseller User Name *" name="reseller_username" id="reseller_username" value={bank.reseller_username} onChange={eventHandler} autoComplete="off" />

                                {errors.reseller_username &&
                                    <span className='text-red small d-block'>* {errors.reseller_username}</span>}

                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="password" className="form-control team-name" placeholder="Reseller Password *"
                                    name="reseller_password" id="reseller_password" value={bank.reseller_password} onChange={eventHandler} autoComplete="new-password" />


                                {errors.reseller_password &&
                                    <span className='text-red small d-block'>* {errors.reseller_password}</span>}
                            </div>
                            {/* <div className="col-md-4 mt-24">
                                    <input type="text" className="form-control team-name" placeholder="Application Template Id *" name="application_template_id" id="application_template_id" value={bank.application_template_id} onChange={eventHandler} />


                                    {errors.application_template_id &&
                                        <span className='text-red small d-block'>* {errors.application_template_id}</span>}
                                </div>
                                <div className="col-md-4 mt-24">
                                    <input type="text" className="form-control team-name" placeholder="Terminal Template Id *" name="terminal_template_ids" id="terminal_template_ids" value={bank.terminal_template_ids} onChange={eventHandler} />


                                    {errors.terminal_template_ids &&
                                        <span className='text-red small d-block'>* {errors.terminal_template_ids}</span>}
                                </div> */}

                        </div>

                    </div>






                </div>
                <div className="tab-content" id="pills-tabContent">


                    <div>
                        <h5 className="merchant-title text-left mt-42">Company Details</h5>
                        <div className="row">
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder=" Company Legal Name *" name="company_legal_name" id="company_legal_name" value={bank.company_legal_name} onChange={eventHandler} autoComplete="off" maxlength="50" />

                                {errors.company_legal_name &&
                                    <span className='text-red small d-block'>* {errors.company_legal_name}</span>}

                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Company DBA Name *"
                                    name="company_dba_name" id="company_dba_name" value={bank.company_dba_name} onChange={eventHandler} maxlength="50" />


                                {errors.company_dba_name &&
                                    <span className='text-red small d-block'>* {errors.company_dba_name}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                {/* <input type="text" className="form-control team-name" placeholder="Company Ownership Type" name="company_ownership_type" id="company_ownership_type" value={bank.company_ownership_type} onChange={eventHandler} />


                                        {errors.company_ownership_type &&
                                            <span className='text-red small d-block'>* {errors.company_ownership_type}</span>} */}


                                <select className={classNames("form-control team-name payterm")} id="company_ownership_type" name="company_ownership_type" value={bank.company_ownership_type} onChange={eventHandler} style={{ "color": "#768391" }}>
                                    <option value="" >Company Ownership Type *</option>
                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                    <option value="Corporation">Corporation</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="LLC">LLC</option>
                                    <option value="Non-Profit"> Non-Profit</option>
                                    <option value="Government "> Government </option>
                                </select>

                                {errors.company_ownership_type &&
                                    <span className='text-red small d-block'>* {errors.company_ownership_type}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Company Federal Tax Id *" name="company_federal_tax_id" id="company_federal_tax_id" value={bank.company_federal_tax_id} onChange={eventHandler} minLength="9" maxlength="9" />


                                {errors.company_federal_tax_id &&
                                    <span className='text-red small d-block'>* {errors.company_federal_tax_id}</span>}
                            </div>

                        </div>

                    </div>






                </div>
                <div className="tab-content" id="pills-tabContent">


                    <div>
                        <h5 className="merchant-title text-left mt-42">Company Address</h5>
                        <div className="row">
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Address *" name="address" id="address" value={bank.address} onChange={eventHandler} autoComplete="off" maxlength="50" />

                                {errors.address &&
                                    <span className='text-red small d-block'>* {errors.address}</span>}

                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="tel" className="form-control team-name" placeholder="Postal Code *"
                                    name="postal_code" id="postal_code" value={bank.postal_code} onChange={eventHandler} maxlength="5" />


                                {errors.postal_code &&
                                    <span className='text-red small d-block'>* {errors.postal_code}</span>}
                            </div>
                            <div className="col-md-3 mt-24">

                                <input type="text" className="form-control team-name" placeholder="State *" name="state" id="state" value={bank.state} onChange={eventHandler} autoComplete="off" maxlength="2" />

                                {errors.state &&
                                    <span className='text-red small d-block'>* {errors.state}</span>}

                                {/* <select className={classNames("form-control team-name", { "error": errors.state })} placeholder="State *" id="state" name="state"
                                            // value={state.state}
                                            value={bank.state}
                                            // onSelect={getstatecity(x.state)}
                                            onChange={e => eventHandler(e)}
                                        >
                                            <option value="0">State *</option>
                                            {allstate.map((v, i) => {
                                                return (
                                                    <option key={v._id} value={v._id}  >{v.name}</option>
                                                )
                                            })}
                                        </select>
                                        {errors.state &&
                                            <span className='text-red small d-block'>* {errors.state}</span>} */}
                            </div>
                            <div className="col-md-3 mt-24">

                                <input type="text" className="form-control team-name" placeholder="City *" name="city" id="city" value={bank.city} onChange={eventHandler} autoComplete="off" />

                                {errors.city &&
                                    <span className='text-red small d-block'>* {errors.city}</span>}


                                {/* <select className={classNames("form-control team-name", { "error": errors.city })} placeholder="City *" id="city" name="city"
                                            // value={state.state}
                                            value={bank.city}
                                            // onSelect={getstatecity(x.state)}
                                            onChange={e => eventHandler(e)}
                                        >
                                            <option value="0">City *</option>
                                            {allcity.map((v, i) => {
                                                return (
                                                    <option key={v._id} value={v._id}  >{v.name}</option>
                                                )
                                            })}
                                        </select>


                                        {errors.city &&
                                            <span className='text-red small d-block'>* {errors.city}</span>} */}
                            </div>

                        </div>

                    </div>






                </div>
                <div className="tab-content" id="pills-tabContent">


                    <div>
                        <h5 className="merchant-title text-left mt-42">Owner Details</h5>
                        <div className="row">
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="First Name *" name="first_name" id="first_name" value={bank.first_name} onChange={eventHandler} autoComplete="off" maxlength="40" />

                                {errors.first_name &&
                                    <span className='text-red small d-block'>* {errors.first_name}</span>}

                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Last Name *"
                                    name="last_name" id="last_name" value={bank.last_name} onChange={eventHandler} maxlength="50" />


                                {errors.last_name &&
                                    <span className='text-red small d-block'>* {errors.last_name}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="email" className="form-control team-name" placeholder="Email Address *" name="email_address" id="email_address" value={bank.email_address} onChange={eventHandler} />


                                {errors.email_address &&
                                    <span className='text-red small d-block'>* {errors.email_address}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                <InputMask mask="999-999-9999" className={classNames("form-control team-name", { "error": errors.phone })} value={bank.phone} onChange={eventHandler} maskChar={"_"} placeholder="bank * " name="phone" id="phone" />
                                {/* <input type="number" className="form-control team-name" placeholder="Phone *" name="phone" id="phone" value={bank.phone} onChange={eventHandler} maxlength="10" /> */}


                                {errors.phone &&
                                    <span className='text-red small d-block'>* {errors.phone}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                {/* <input type="text" className="form-control team-name" placeholder="Date Of Birth " name="date_of_birth" id="date_of_birth" value={bank.date_of_birth} onChange={eventHandler} /> */}
                                {/* <DatePicker
                                            selected={startDate}
                                            placeholderText="Date Of Birth *"
                                            // onChange={(date) => setStartDate(date)}
                                            onChange={date => eventHandler({ target: { value: date, name: 'startDate', id: "due_date" } })}
                                            // onChange={eventHandler}
                                            // value={date.toString()}

                                            id="due_date"
                                            className="form-control team-name"
                                        /> */}
                                {/* <input type="date" id="due_date" name="startDate" value={bank.due_date} onChange={(e) => eventHandler(e)} minLength="8" className={classNames("form-control team-name ", {
                                    error: errors.startDate,
                                })} /> */}
                                {/* <DatePicker
                                    selected={startDate}
                                    // onChange={(date) => setStartDate(date)}
                                    placeholderText="Date Of Birth *"
                                    onChange={date => eventHandler({ target: { value: date, name: 'startDate', id: "due_date" } })}
                                    id="due_date"
                                    className="form-control team-name"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    autoComplete='off'
                                /> */}

                                <ThemeProvider theme={materialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    clearable
                                                    value={selectedDate}
                                                    placeholder="mm/dd/yyyy"
                                                    onChange={date => handleDateChange(date)}
                                                    minDate={new Date()}
                                                    format="MM/dd/yyyy"
                                                    mask="__/__/____"
                                                    inputVariant="outlined"
                                                    autoOk="true"
                                                    InputAdornmentProps={{ position: "end" }}
                                                    className={classNames(" form-control team-name")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>

                                {errors.dueDate &&
                                    <span className='text-red small d-block'>* {errors.dueDate}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Social Security Number *" name="social_security_number" id="social_security_number" value={bank.social_security_number} onChange={eventHandler} maxlength="9" minLength="9" />


                                {errors.social_security_number &&
                                    <span className='text-red small d-block'>* {errors.social_security_number}</span>}
                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Drivers License Number *" name="drivers_license_number" id="drivers_license_number" value={bank.drivers_license_number} onChange={eventHandler} maxlength="20" />


                                {errors.drivers_license_number &&
                                    <span className='text-red small d-block'>* {errors.drivers_license_number}</span>}
                            </div>

                        </div>

                    </div>






                </div>
                <div className="tab-content" id="pills-tabContent">


                    <div>
                        <h5 className="merchant-title text-left mt-42">Bank</h5>
                        <div className="row">
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Bank Routing Number *" name="bank_routing_number" id="bank_routing_number" value={bank.bank_routing_number} onChange={eventHandler} autoComplete="off" minLength="9" maxlength="9" />

                                {errors.bank_routing_number &&
                                    <span className='text-red small d-block'>* {errors.bank_routing_number}</span>}

                            </div>
                            <div className="col-md-4 mt-24">
                                <input type="text" className="form-control team-name" placeholder="Bank Account Number *"
                                    name="bank_account_number" id="bank_account_number" value={bank.bank_account_number} onChange={eventHandler} maxlength="17" />


                                {errors.bank_account_number &&
                                    <span className='text-red small d-block'>* {errors.bank_account_number}</span>}
                            </div>


                        </div>

                    </div>






                </div>

            </div>

            <div className="mt-3 text-center">
                {/* 
                {
                    formStep == 1 &&  */}
                <div>
                    <Link to="/login" className='btn-login' style={{ "color": "#f9e7e9" }}>
                        <button
                            type="submit"
                            className="btn btn-login font-weight-medium auth-form-btn "
                        // disabled={props.signup?.loading}
                        // onClick={back}
                        >
                            Skip
                        </button>
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-login font-weight-medium auth-form-btn ml-2"
                        // disabled={props.signup?.loading}
                        onClick={submit}
                    >
                        Save And continue
                    </button>
                </div>
                {/* } */}
            </div>
            {/* <Toaster /> */}
            {/* </div>
            </div> */}
        </React.Fragment>
    )
}

// export default BankInfo;

const mapStateToProp = state => ({
    submit: state.createBankDetailsReducer,
    deleteMsg: state.deleteBankReducer,
    editMsg: state.editBankReducer,
    merchand: state.createMerchandAccountReducer,
    signup: state.signupReducer

})

const mapDispatchToProps = dispatch => ({
    // createFieldhandler: user => dispatch(createField(user)),
    // resetCreateBankdetailsReducer: () => dispatch({ type: RESET_BANKDETAILS }),
    // reseteditBankdetailsReducer: () => dispatch({ type: RESET_EDIT_BANK }),
    // resetDeleteBankReducer: () => dispatch({ type: RESET_BANK }),
    // bankDetailshandler: user => dispatch(createBankDetails(user)),
    // getBanksHandler: user => dispatch(getBanks(user)),
    // deleteBankdetailsHandler: user => dispatch(deleteBankdetail(user)),
    getAllStateHandler: user => dispatch(getAllState()),
    getAllCityHandler: user => dispatch(getAllCity(user)),
    createMerchandAccountHandler: user => dispatch(createMerchandAccount(user))
})
export default connect(mapStateToProp, mapDispatchToProps)(SignupMerchant);
// 1556